
@font-face {
  font-family: "Dosis";
  src: url("./assets/webfonts/dosis-v19-latin-regular.woff2") format("woff2"),
      url("./assets/webfonts/dosis-v19-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/webfonts/open-sans-v26-latin-regular.woff2") format("woff2"),
       url("./assets/webfonts/open-sans-v26-latin-regular.woff") format("woff");
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
